<template>
  <div>
    <section class="page-top">
      <transition :name="exclusiontext">
        <h4>Scroll Down</h4>
      </transition>
    </section>

    <section id="contact-section">

      <div class="contact-content">
        <h1>Contact</h1>
        <h2>Let's Make it Happen</h2>

        <div class="contact-copy">
          <p class="copy">I'm ready to put my skills to use reaching your goals, and that means it's time to get the ball rolling on our next project. All it takes to get started is to reach out through the form below. I'll be waiting.</p>
        </div>

        <form class="contact-form" action="https://formspree.io/f/mpzbpjkr" method="POST">
          <small>* Required Field</small>
    
          <label for="cfn">Name<span>*</span></label>
          <input type="text" id="fname" name="name" required>
    
          <label for="cfm">Email<span>*</span></label>
          <input type="email" id="mail" name="email" required>
    
          <label for="cfo">Organization</label>
          <input type="text" id="org" name="org">
    
          <label for="cfm">Message</label>
          <textarea maxlength="1250" name="message"></textarea>
    
          <input type="submit" value="Send">
    
        </form>


      </div>

    </section>
    <footer-info class="footer-back" />
  </div>
</template>

<script>
import FooterInfo from "../components/FooterInfo.vue"

export default {
  name: "Contact",
  components: {
    FooterInfo,
  },
  data() {
    return {
      items: []
    }
  },

}
</script>

<style lang="scss">

#contact-section {
    box-sizing: border-box;
    min-height: 50vh;
    padding-top: 4em;
    padding-bottom: 5em;
    margin: 0 auto;
    background-color: $transparent_offblack;
    box-shadow: 0px -3px 10px black;
    overflow: hidden;
}

.contact-content {
  max-width: $smallContWidth;
  padding: 1em;
  margin: 0 auto;

  h1 {
    font-size: 10vw;
    text-align: left;
    -webkit-text-stroke: 1px $light;
    color: transparent;
    margin-top: -2rem;
    transition: all 0.5s ease;
  
    @include respond-to('medium') {
      font-size: 12vw;
      -webkit-text-stroke: 0px $light;
      color: $light;
      margin-top: 0;
    }
  
    @include respond-to('small') {
      font-size: 13vw;
      font-weight: 500;
    }
  }

  h2 {
    clear: both;
      font-size: 4em;
      text-align: left;
      color: $light;
      margin-left: 1rem;
      margin-bottom: 0.25em;
  
      @include respond-to('medium') {
        font-size: 2em;
        margin-left: 0.25rem;
      }
  
      @include respond-to('small') {
        margin-left: 0;
      }
  
      &::before {
        content: "//";
        font-size: 1.25em;
        color: $light;
        font-weight: 500;
        margin-right: 3px;
      }
  }
}

.contact-copy {
  margin-top: 4em;
}

.contact-form {
  width: 100%;
  font-size: 1.25em;
  margin-bottom: 15vh;
  box-sizing: border-box;

  small {
    font-size: 0.85em;
    color: $accent;
    float: left;
    letter-spacing: 0.1em;
    margin-bottom: 2em;
  }
  
  label {
    float: left;
    margin: 0.5em 0;
    letter-spacing: 0.05em;
    clear: both;

    span {
      color: $accent;
    }
  }

  input {
    box-sizing: border-box;
    width: 100%;
    height: 2.5em;
    padding: 0.1em 1em;
    margin-bottom: 1.5em;
    font-family: ballinger-condensed, Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.1em;
    letter-spacing: 0.05em;
    color: $light;
    border: 2px solid $light;
    background-color: $transparent_offblack;
    transition: all 0.25s;
    outline: none;

    &:focus {
      color: $dark;
      background-color: $light;
      transition: all 0.25s;
    }
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    min-height: 20em;
    padding: 0.5em 1em;
    margin-bottom: 1.5em;
    font-family: ballinger-condensed, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1.1em;
    letter-spacing: 0.05em;
    color: $light;
    border: 2px solid $light;
    background-color: $transparent_offblack;
    transition: all 0.25s;
    outline: none;
    resize: none;

    &:focus {
      color: $dark;
      background-color: $light;
      transition: all 0.25s;
    }
  }

  input[type=submit] {
    float: left;
    clear: both;
    width: 30%;
    background-color: $light;
    color: $dark;
    letter-spacing: 0.08em;
    cursor: pointer;
    transition: all 0.15s;

    @include respond-to('small') {
      width: 100%;
    }

    &:hover {
      background-color: $accent;
      color: $light;
      transition: all 0.15s;
    }
  }
}

</style>